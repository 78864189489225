.Warning {
  margin-top: 1rem;
  border-radius: $border-radius-s;
  background-color: $white;

  .MuiPaper-root {
    background-color: #ed6e181a;
    padding: 0.6rem;
    border-left: 0.3rem solid $primary;

    .MuiAlert-icon {
      .MuiSvgIcon-root {
        @include themed($themes) {
          background: t("backgroundColor");
        }
        border-radius: 50%;
        margin-left: -1.8rem;
        margin-top: -2.4rem;
        color: $primary;
        width: 2.3rem;
        height: 2.3rem;
      }
    }

    .MuiAlert-message {
      text-align: left;
      font-size: 1.2rem;
      color: black;
    }
  }
}
