.Text {
  text-align: center;

  p {
    @include themed($themes) {
      color: t("text-color-secondary");
    }
    font-weight: 500;
    line-height: 140%;
  }

  span {
    font-weight: 300;
    line-height: 140%;
  }
}
