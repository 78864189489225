.HeaderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .HeaderTemplate {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $full-width;
    padding: 2rem;
    position: sticky;
    top: 0;
    box-sizing: border-box;
    @include themed($themes) {
      background-color: t("pagesBackgroundColor");
    }
    z-index: 2;

    .header-grid {
      width: $full-width;
      height: 5rem;
      display: flex;
      align-items: center;

      .title {
        .MuiTypography-root {
          text-align: center !important;
          font-size: 1.6rem;
        }
      }
    }

    .linearProgress {
      margin-top: 2rem;
      width: $full-width;
      color: $primary;
      background-color: $grey;

      .MuiLinearProgress-bar {
        background-color: $primary;
      }
    }
  }

  .childrenContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    box-sizing: border-box;
    width: 100%;
    flex: 1;

    .question {
      width: $full-width;
      margin: 3rem 0rem;
      text-align: start;

      .MuiTypography-root {
        font-weight: 600;
        font-size: 1.6rem;
      }
    }

    .questionsContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      height: 100%;

      .MuiPopper-root {
        .MuiAutocomplete-paper {
          @include themed($themes) {
            background-color: t("pagesBackgroundColor");
            color: t("text-color-primary");
          }
          font-size: 1.4rem;
        }
      }
    }
  }

  .stickyFooter {
    position: sticky;
    bottom: 0;
    background-color: $white;
    padding: 2rem;
    box-sizing: border-box;
    width: $full-width;
    z-index: 2;
    @include themed($themes) {
      background-color: t("pagesBackgroundColor");
    }
  }
}
