.InputAutocomplete {
  width: $full-width;

  .MuiFormLabel-root {
    @include themed($themes) {
      color: t("text-color-primary");
    }
    font-size: 1.4rem;
  }

  .MuiInputAdornment-root > p {
    @include themed($themes) {
      color: t("text-color-primary") !important;
    }
  }

  .MuiInputBase-root {
    @include themed($themes) {
      color: t("text-color-primary") !important;
    }
    font-size: 1.4rem;
  }

  .MuiButtonBase-root {
    @include themed($themes) {
      color: t("text-color-primary") !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    @include themed($themes) {
      border-color: t("text-color-primary");
    }
    font-size: 1.4rem;
  }
  .Mui-focused fieldset {
    border-color: $primary !important;
  }
}

.MuiPopper-root {
  .MuiPaper-root {
    font-size: 1.4rem;
  }
}
