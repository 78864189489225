// COLORS
$primary: #ed6e18;
$primary-gradient: linear-gradient(
  225deg,
  #e04037 0%,
  #e96039 29.69%,
  #eb6e29 61.46%,
  #f2942f 100%
);
$primary-light-gradien: linear-gradient(97deg, #f2942f 21.96%, #ed6e18 69.49%);
$secondary-gradient: linear-gradient(
  0deg,
  rgba(237, 110, 24, 0.1) 0%,
  rgba(237, 110, 24, 0.1) 100%
);
$secondary-gradient: #fdf0e8;
$white: white;
$black: black;
$grey: #f1f1f1;

//radius
$border-radius-s: 0.4rem;
$border-radius-m: 1rem;
$border-radius-l: 4rem;

//sizes
$full-view-width: 100vw;
$full-view-height: 100vh;
$full-width: 100%;
$full-height: 100%;
