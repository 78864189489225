.Title {
  text-align: center;

  h1 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 525;
    line-height: 140%;
  }

  h3 {
    text-align: left;
    font-size: 2rem;
    font-weight: 400;
    line-height: 140%;
  }
}
