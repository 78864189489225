.TouchButton {
  width: $full-width;
  height: 8.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start !important;
  text-transform: none !important;
  @include themed($themes) {
    color: t("text-color-primary");
  }
  border-color: $grey !important;
  margin-bottom: 1rem !important;
  border-radius: 0.8rem !important;
  font-size: 1.6rem !important;
}

.activeTouchButton {
  border-color: $primary !important;
  background-color: rgba(237, 110, 24, 0.1) !important;
}
