.InputDate {
  width: $full-width;

  .MuiFormLabel-root {
    @include themed($themes) {
      color: t("text-color-primary");
    }
    font-size: 1.4rem;
  }

  .MuiOutlinedInput-notchedOutline {
    @include themed($themes) {
      border-color: t("text-color-primary");
    }
    font-size: 1.4rem;
  }

  .MuiInputBase-input {
    @include themed($themes) {
      color: t("text-color-primary");
    }
    font-size: 1.4rem;
  }

  .MuiButtonBase-root {
    @include themed($themes) {
      color: t("text-color-primary") !important;
    }
  }
  .Mui-focused fieldset {
    border-color: $primary !important;
  }
}

.MuiDialog-root {
  .MuiPaper-root {
    @include themed($themes) {
      background-color: t("pagesBackgroundColor");
      color: t("text-color-primary") !important;
    }

    .MuiPickersToolbar-root {
      span {
        @include themed($themes) {
          color: t("text-color-primary") !important;
        }
      }
    }
    //content
    .MuiPickersLayout-contentWrapper {
      .MuiPickersCalendarHeader-root {
        .MuiButtonBase-root {
          @include themed($themes) {
            color: t("text-color-primary") !important;
          }
        }
      }

      .MuiPickersFadeTransitionGroup-root {
        font-size: 1.4rem;
        .MuiPickersMonth-monthButton {
          font-size: 1.4rem !important;
          &.Mui-selected {
            background-color: $primary;
          }

          &.Mui-disabled {
            color: #ababab99;
          }
        }

        .MuiPickersYear-yearButton {
          font-size: 1.4rem !important;
          &.Mui-selected {
            background-color: $primary;
          }

          &.Mui-disabled {
            color: #ababab99;
          }
        }
      }
    }
    //footer
    .MuiDialogActions-root {
      .MuiButtonBase-root {
        font-size: 1.4rem;
        color: $primary;
      }
    }
  }

  .MuiTypography-root {
    font-size: 1.4rem;
  }
}
