//https://itnext.io/structuring-your-sass-projects-c8d41fa55ed4

/*Typo*/
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

/* abstract */
@import "abstracts/variables";
@import "abstracts/mixins";

//base

/* themes */
@import "theme/theme-config";

/* components */
// -> atoms
@import "components/atoms/Title/Title";
@import "components/atoms/Text/Text";
@import "components/atoms/StyledButton/StyledButton";
@import "components/atoms/LogoSvg/LogoSvg";
@import "components/atoms/TouchButton/TouchButton";
@import "components/atoms/InputText/InputText";
@import "components/atoms/InputNumber/InputNumber";
@import "components/atoms/InputDate/InputDate";
@import "components/atoms/InputSelect/InputSelect";
@import "components/atoms/InputAutocomplete/InputAutocomplete";
@import "components/atoms/SpinnerLoader/SpinnerLoader";
@import "components/atoms/Warning/Warning";

// -> molecules

// -> organisme
@import "components/organisms/StartingPage/StartingPage";

// -> pages
@import "components/pages/ErrorPage/ErrorPage";
@import "components/pages/MainPage/MainPage";
@import "components/pages/InfoValidation/InfoValidation";

// -> templates
@import "components/templates/Container/Container";
@import "components/templates/Header/Header";

/* layout */

/* global */
:root {
  font-size: 0.625em; /* fallback IE8+ */
  font-size: calc(1em * 0.625);
}

body {
  @include themed($themes) {
    background: t("backgroundColor");
    color: t("text-color-primary");
  }
  font-family: "Lato";
  transition: all 0.5s linear;
  overflow-x: hidden;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 1.6rem;
}

.App {
  @include themed($themes) {
    background: t("backgroundColor");
    color: t("text-color-primary");
  }
  width: $full-view-width;
  min-height: $full-view-height;
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
}

.noPaddingTop {
  padding-top: 0 !important;
}

.alignCenter {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: $full-width;
  height: $full-height;
}

.loading {
  background-color: #f9f8f8 !important;
}
