.StartingPage {
  width: $full-width;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
  box-sizing: border-box;
  height: 100%;

  .block {
    width: $full-width;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
    text-align: center;

    .Title {
      max-width: 30rem;

      .MuiTypography-root {
        font-weight: 600;
        font-size: 1.8rem !important;
      }
    }

    .Text {
      margin: 1.5rem 0;
      .MuiTypography-root {
        font-weight: 400;
        font-size: 1.4rem !important;
      }
    }
  }

  .button {
    margin-top: auto;
    margin-bottom: 2rem;
  }

  .text {
    margin-top: 1rem;
    p {
      text-align: left;
      padding: 0rem 1rem;
    }
  }

  p,
  h3 {
    text-align: center;
  }
}
