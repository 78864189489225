.ErrorPage {
  @include themed($themes) {
    background: t("backgroundColor");
    color: t("text-color-primary");
  }
  width: $full-width;
  display: flex;
  flex-direction: column;
  align-items: center;
}
