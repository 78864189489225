.InfoValidation {
  display: flex;
  flex-direction: column;
  width: $full-width;

  .dataDisplay {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 2rem;

    .MuiFormControl-root {
      margin-top: 2rem;
    }
  }
}
